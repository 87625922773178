import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of, combineLatest } from 'rxjs';
import { IShipmentsState, Shipment, CatalogsState, Catalog } from 'wms-lib';
import * as actions from 'wms-lib';
import { Router } from '@angular/router';

@Component({
    selector: 'app-shipment-general-form',
    templateUrl: './shipment-general-form.component.html',
    styleUrls: ['./shipment-general-form.component.scss']
})
export class ShipmentGeneralFormComponent implements OnInit {
    shipment: Shipment;
    priorities$: Observable<Catalog[]> = of([]);
    conveyance_types$: Observable<Catalog[]> = of([]);
    classifications$: Observable<Catalog[]> = of([]);

    constructor(private store: Store, private router: Router) { }

    ngOnInit() {
        this.priorities$ = this.store.select(CatalogsState.getArrayCatalog('priorities'));
        this.conveyance_types$ = this.store.select(CatalogsState.getArrayCatalog('conveyance_types'));
        this.classifications$ = this.store.select(CatalogsState.getArrayCatalog('classifications'));
        combineLatest(this.store.select(state => state.shipmentsState.shipment_template),this.priorities$,this.conveyance_types$).
            subscribe(([shipment, priorities, conveyance_types]: [Shipment, Catalog[], Catalog[]]) => {
                if (shipment && priorities && conveyance_types){
                    priorities.sort((c: Catalog) => c.sequence).reverse();
                    conveyance_types.sort((c: Catalog) => c.sequence).reverse();
                    shipment.priority_id = shipment.priority_id || (priorities[0] && priorities[0].id);
                    shipment.conveyance_type_id = shipment.conveyance_type_id || (conveyance_types[0] && conveyance_types[0].id);
                    this.shipment = shipment;
                }
            })
    }

    save() {
        this.shipment.validate();

        if (this.shipment.isValid) {
            this.store.dispatch(new actions.SaveShipmentAction({ shipment: this.shipment })).subscribe((state: any) => {
                if (!state.shipmentsState.shipment_template) {
                    this.router.navigate(['/shipments', 'orders']);
                }
            });
        }
    }

}
