import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User, AuthenticationService, LocalStorage, Client, ICatalog } from 'wms-lib';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as actions from 'wms-lib';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @Input() user: User;
  @Input() current_user: User;
  @Output() onFinish: EventEmitter<User> = new EventEmitter<User>();
  roles$: Observable<any[]>;
  clients$: Observable<any[]>;
  warehouses$: Observable<any[]>;
  notifications = [
    {label:'Shipment order created', value: Math.pow(2,0)},
    {label:'Shipment closed', value: Math.pow(2,1)},
    {label:'Short arrive', value: Math.pow(2,2)},
    {label:'Receipts', value: Math.pow(2,3)},
    {label:'Export', value: Math.pow(2,4)},  
    {label:'Package Damages', value: Math.pow(2,5)},
    {label:'Bundle Damages', value: Math.pow(2,6)},
    {label:'Weekly Inventory', value: Math.pow(2,7)},
  ];

  notificationsRoles = [
	  {label:'Receipts Unprocessed email', value: Math.pow(2,0), roles: ['admin','supervisor']}
  ];

  rolNotifications = [];

  fas = [
    {label:'None', value: null},
    {label:'TOTP', value: 'SOFTWARE_TOKEN_MFA'},
    {label:'SMS', value: 'SMS_MFA'},
    {label:'EMAIL', value: 'CUSTOM_CHALLENGE'}
  ];
  client_id: number;
  role: string;
  constructor(private store: Store, private authenticationService : AuthenticationService) { }

  ngOnInit() {
    this.authenticationService.currentUser.subscribe((u: User) => {
      this.current_user = u;
      if (!this.user) {
        this.user = this.current_user;
        this.user.takeSnapshoot(this.user.saveProp.concat(["avatar_url"]));
        this.store.dispatch(new actions.GetRolesAction());
      } else if (this.user.id == u.id) {
        this.user = u;
        this.user.takeSnapshoot(this.user.saveProp.concat(["avatar_url"]));
      }
    });
    this.user.takeSnapshoot(this.user.saveProp.concat(["avatar_url"]));
    this.roles$ = this.store.select((state) => state.catalogsState.roles).pipe(
      map((data: []) => (data || []).map((r: string) => ({value: r, label: r})))
    );
    // filter the clients active
    this.clients$ = this.store.select((state) => state.catalogsState.clients).pipe(
    	map((clients: {[id:number]: Client}) => _(clients).filter((p: Client) => p.active).value() ) );

    this.warehouses$ = this.store.select((state) => state.catalogsState.warehouses).pipe(
      map((warehouses: {[id:number]: ICatalog}) => _(warehouses).map((p: ICatalog) => ({label: p.name, value: p.id})).value() )
    );
  }

  save() {
    this.store.dispatch(new actions.SaveUserAction(this.user));
    if (this.user.id == this.current_user.id)
      LocalStorage.saveString('currentUser', JSON.stringify(this.user));
    this.onFinish.emit();
  }

  cancel() {
    this.user.rollback();
    this.onFinish.emit(this.user.id > 0 ? null : this.user);
  }

  attachAvatar(event: any) {
    let reader = new FileReader();
    reader.onloadend = () => this.user.avatar_url = <string>reader.result;
    reader.readAsDataURL(event.srcElement.files[0]);
  }

  onrightClick(event: any, client: Client, overlaypanel: any) {
    if (this.user.client_ids.includes(client.id)){
      this.client_id = client.id;
      overlaypanel.toggle(event);
      return false;
    }
  }

  onrightClickRoles(event: any, role: string, overlaypanel: any) {
	  if (this.user.roles.includes(role)){
		  this.rolNotifications = [];
		  this.notificationsRoles.forEach((notification) => {
			  if (notification.roles.includes(role)){
				  this.rolNotifications.push(notification);
			  }
		  });
		  if (this.rolNotifications.length > 0) overlaypanel.toggle(event);
		  return false;
	  }
  }

  get modelChange(): boolean {
    return this.user.haveChanged(this.user.saveProp.concat(["avatar_url"]));
  }

  get isProfile(): boolean {
    return this.user.id == this.current_user.id;
  }

  get canChangeMFA(): boolean {
    return this.current_user.isAdmin;
  }
}
